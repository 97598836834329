<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label-width="70px" label="任务编号" prop="taskNo">
            <el-input
              style="width: 140px"
              clearable
              v-model="queryForm.taskNo"
              placeholder="请输入任务编号"
            />
          </el-form-item>
          <el-form-item label-width="70px" label="创建人" prop="createBy">
            <el-input
              style="width: 140px"
              clearable
              v-model="queryForm.createBy"
              placeholder="请输入创建人"
            />
          </el-form-item>
          <el-form-item label-width="70px" label="任务名" prop="taskName">
            <el-select
              clearable
              v-model="queryForm.taskName"
              placeholder="请选择"
              style="width: 140px"
            >
              <el-option
                v-for="(item, index) in taskNameList"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="50px" label="类型" prop="taskType">
            <el-select
              clearable
              v-model="queryForm.taskType"
              placeholder="请选择类型"
              style="width: 140px"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="50px" label="状态" prop="taskStatus">
            <el-select
              clearable
              v-model="queryForm.taskStatus"
              placeholder="请选择状态"
              style="width: 140px"
            >
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label-width="50px" label="时间">
            <el-date-picker
              v-model="beginTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              unlink-panels
              @change="pickerClick"
              style="width: 350px"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 280px)">
      <el-table
        ref="tableSort"
        border
        :data="list"
        height="100%"
        :row-style="{ height: '30px' }"
        :cell-style="{ padding: '2px' }"
      >
        <el-table-column
          align="center"
          prop="taskNo"
          label="任务编号"
          show-overflow-tooltip
          width="150"
        />
        <el-table-column
          align="center"
          prop="taskName"
          label="任务名称"
          width="120"
        />
        <el-table-column
          align="center"
          prop="taskTypeName"
          label="任务类型"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          align="center"
          prop="taskStatusName"
          label="任务状态"
          show-overflow-tooltip
          width="100"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.taskStatus == '1'">
              <el-tag type="info">{{ scope.row.taskStatusName }}</el-tag>
            </span>
            <span v-if="scope.row.taskStatus == '2'">
              <el-tag type="success">{{ scope.row.taskStatusName }}</el-tag>
            </span>
            <span v-if="scope.row.taskStatus == '3'">
              <el-tag type="danger">{{ scope.row.taskStatusName }}</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          class-name="resultMsg"
          align="left"
          prop="resultMsg"
          label="处理结果信息"
        />
        <el-table-column
          align="center"
          prop="startTime"
          label="开始时间"
          width="100"
        />
        <el-table-column
          align="center"
          prop="endTime"
          label="结束时间"
          width="100"
        />
        <el-table-column
          align="center"
          prop="createBy"
          label="创建人"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          align="center"
          label="操作"
          show-overflow-tooltip
          width="90"
        >
          <template #default="{ row }">
            <el-button type="text">
              <el-link
                style="font-size: 12px"
                type="primary"
                :disabled="!row.resultFileUrl"
                :href="row.resultFileUrl"
              >
                下载结果文件
              </el-link>
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { pageTaskManage, listAllTaskName } from '@/api/cmsApi'
  export default {
    name: 'taskManage',
    components: {},
    data() {
      return {
        taskNameList: [],
        typeList: [
          { id: 1, name: '导入' },
          { id: 2, name: '导出' },
        ],
        statusList: [
          { id: 1, name: '处理中' },
          { id: 2, name: '处理成功' },
          { id: 3, name: '处理失败' },
        ],
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        height: this.$baseTableHeight(3) + 50,
        beginTime: [],
        queryForm: {
          taskName: '',
          taskType: '',
          taskStatus: '',
          createBy: '',
          taskNo: this.$route.params.taskNo ? this.$route.params.taskNo : '',
          startTime: '',
          endTime: '',
          pageNum: 1,
          pageSize: 30,
        },
        pickerOptions: {},
        queryRequsetTimer: null,
      }
    },
    created() {
      this.listAllTaskName()
    },
    mounted() {
      this.queryList()

      // 定时刷新页面（7秒）
      this.queryRequsetTimer = setInterval(() => {
          this.queryList()
        }, 7000)
    },
    deactivated() {
      clearInterval(this.queryRequsetTimer)
      // this.queryList()
    },
    beforeDestroy() {
      clearInterval(this.queryRequsetTimer)
      // window.removeEventListener('resize', this.handleHeight)
    },

    methods: {
      // 重置
      reset(queryForm) {
        this.beginTime = []
        this.queryForm.startTime = ''
        this.queryForm.endTime = ''
        this.$refs['queryForm'].resetFields()
        this.queryForm.taskNo = ''
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      pickerClick() {
        this.queryForm.startTime = this.beginTime
          ? this.beginTime[0] + ' 00:00:00'
          : ''
        this.queryForm.endTime = this.beginTime
          ? this.beginTime[1] + ' 00:00:00'
          : ''
        this.queryList()
      },
      // 查询
      async queryList() {
        this.listLoading = true
        const res = await pageTaskManage(this.queryForm)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
      async listAllTaskName() {
        const res = await listAllTaskName()
        this.taskNameList = res.data
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
    ::v-deep .resultMsg .cell {
      white-space: pre-wrap !important;
    }
  }

  ::v-deep .left-panel {
    margin: 0px !important;
  }
  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
</style>
