var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "70px",
                        label: "任务编号",
                        prop: "taskNo"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "请输入任务编号" },
                        model: {
                          value: _vm.queryForm.taskNo,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "taskNo", $$v)
                          },
                          expression: "queryForm.taskNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "70px",
                        label: "创建人",
                        prop: "createBy"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "请输入创建人" },
                        model: {
                          value: _vm.queryForm.createBy,
                          callback: function($$v) {
                            _vm.$set(_vm.queryForm, "createBy", $$v)
                          },
                          expression: "queryForm.createBy"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "70px",
                        label: "任务名",
                        prop: "taskName"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.queryForm.taskName,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "taskName", $$v)
                            },
                            expression: "queryForm.taskName"
                          }
                        },
                        _vm._l(_vm.taskNameList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "50px",
                        label: "类型",
                        prop: "taskType"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "请选择类型" },
                          model: {
                            value: _vm.queryForm.taskType,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "taskType", $$v)
                            },
                            expression: "queryForm.taskType"
                          }
                        },
                        _vm._l(_vm.typeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "50px",
                        label: "状态",
                        prop: "taskStatus"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "请选择状态" },
                          model: {
                            value: _vm.queryForm.taskStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "taskStatus", $$v)
                            },
                            expression: "queryForm.taskStatus"
                          }
                        },
                        _vm._l(_vm.statusList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "50px", label: "时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions,
                          "unlink-panels": ""
                        },
                        on: { change: _vm.pickerClick },
                        model: {
                          value: _vm.beginTime,
                          callback: function($$v) {
                            _vm.beginTime = $$v
                          },
                          expression: "beginTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 280px)" } },
        [
          _c(
            "el-table",
            {
              ref: "tableSort",
              attrs: {
                border: "",
                data: _vm.list,
                height: "100%",
                "row-style": { height: "30px" },
                "cell-style": { padding: "2px" }
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "taskNo",
                  label: "任务编号",
                  "show-overflow-tooltip": "",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "taskName",
                  label: "任务名称",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "taskTypeName",
                  label: "任务类型",
                  "show-overflow-tooltip": "",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "taskStatusName",
                  label: "任务状态",
                  "show-overflow-tooltip": "",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.taskStatus == "1"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v(_vm._s(scope.row.taskStatusName))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.taskStatus == "2"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(_vm._s(scope.row.taskStatusName))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.taskStatus == "3"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(_vm._s(scope.row.taskStatusName))
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  "class-name": "resultMsg",
                  align: "left",
                  prop: "resultMsg",
                  label: "处理结果信息"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "startTime",
                  label: "开始时间",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "endTime",
                  label: "结束时间",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "createBy",
                  label: "创建人",
                  "show-overflow-tooltip": "",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  "show-overflow-tooltip": "",
                  width: "90"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text" } },
                          [
                            _c(
                              "el-link",
                              {
                                staticStyle: { "font-size": "12px" },
                                attrs: {
                                  type: "primary",
                                  disabled: !row.resultFileUrl,
                                  href: row.resultFileUrl
                                }
                              },
                              [_vm._v(" 下载结果文件 ")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }